<template>
	<div>
		<div class="infoarea">
			<div class="anchorinfo">
				<div class="flex">
					<img
						alt=""
						src="https://p9.douyinpic.com/aweme/1080x1080/a16e00180532cd9d0107.jpeg?from=2956013662"
					/>
					<div class="info">
						<p class="fs16">
							罗拉密码-周三下午2点年中大促服装专场
						</p>
						<p class="fs14">
							<span class="color-2">ID：</span>
							LOORA88888
						</p>
						<p class="fs14"></p>
					</div>
				</div>
				<!---->
			</div>
			<div class="title fs12">最新直播场次数据</div>
			<div class="datainfo">
				<div class="dataitem">
					<p class="fs24">324.10万</p>
					<p class="fs12 color-2">观看人数</p>
				</div>
				<div class="cut-line"></div>
				<div class="dataitem">
					<p class="fs24">157件</p>
					<p class="fs12 color-2">全场商品数</p>
				</div>
				<div class="cut-line"></div>
				<div class="dataitem">
					<p class="fs24">37.45万件</p>
					<p class="fs12 color-2">全场销量</p>
				</div>
				<div class="cut-line"></div>
				<div class="dataitem">
					<p class="fs24">3508.79万</p>
					<p class="fs12 color-2">全场销售额</p>
				</div>
			</div>
		</div>
		<div class="flex_r">
			<div>
				<div class="screen-tab">
					<div
						class="screen-div-item"
						:class="cur === 1 ? 'item-active' : ''"
						@click="change(1)"
					>
						<img
							v-if="cur === 1"
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_a1.png"
							alt=""
						/><img
							v-else
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_1.png"
							alt=""
						/><span>基础分析</span>
					</div>
					<div
						class="screen-div-item"
						:class="cur === 2 ? 'item-active' : ''"
						@click="change(2)"
					>
						<img
							v-if="cur === 2"
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_a2.png"
							alt=""
						/><img
							v-else
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_2.png"
							alt=""
						/><span>场次分析</span>
					</div>
					<div
						class="screen-div-item"
						:class="cur === 3 ? 'item-active' : ''"
						@click="change(3)"
					>
						<img
							v-if="cur === 3"
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_a4.png"
							alt=""
						/><img
							v-else
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_4.png"
							alt=""
						/><span>带货分析</span>
					</div>
					<div
						class="screen-div-item"
						:class="cur === 4 ? 'item-active' : ''"
						@click="change(4)"
					>
						<img
							v-if="cur === 4"
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_a5.png"
							alt=""
							style=""
						/><img
							v-else
							src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/v2.2/icon_5.png"
							alt=""
						/><span>服务及联系</span>
					</div>
				</div>
			</div>
			<div class="data-area dataarea" v-if="cur === 1">
				<div class="goods-item">
					<p class="dotted-title">带货品类TOP5</p>
					<div class="class-item">
						<span>罗拉密码(LooraPwd)</span>
						<span>味滋源</span>
						<span>刻凡</span>
						<span>洁柔(C&amp;S)</span>
						<span>舌里</span>
					</div>
				</div>
				<div class="goods-item">
					<p class="dotted-title">直播速览</p>
					<div class="datainfo">
						<div class="dataitem">
							<p class="fs14 color-2">近30天场次</p>
							<p class="fs24">8场</p>
						</div>
						<div class="cut-line"></div>
						<div style="" class="dataitem">
							<p class="fs14 color-2">历史总场数</p>
							<p class="fs24">51场</p>
						</div>
						<div class="cut-line"></div>
						<div class="dataitem">
							<p class="fs14 color-2">
								近30天场均销量
							</p>
							<p class="fs24">12.48万</p>
						</div>
						<div class="cut-line"></div>
						<div class="dataitem">
							<p class="fs14 color-2">
								近30天场均销售额
							</p>
							<p class="fs24">1063.45万</p>
						</div>
						<div class="cut-line"></div>
						<div class="dataitem">
							<p class="fs14 color-2">
								近30天场均观看人数
							</p>
							<p class="fs24">159.35万</p>
						</div>
					</div>
				</div>
				<div class="goods-item">
					<p class="dotted-title">带货速览</p>
					<div style="" class="cargo-num">
						<div class="cargo-num-item">
							<span>场均带货商品数</span>
							<p>94件</p>
						</div>
						<div class="cargo-num-item">
							<span>场均带货直播价</span>
							<p>80.11元</p>
						</div>
						<div style="width: 314px;"></div>
					</div>
				</div>
				<div class="line">
					<p class="dotted-title">粉丝增长趋势</p>
					<e-charts
						id="idLinezz"
						:options="optionLine"
						class="h100"
					/>
				</div>
				<div class="pies flex_r flex_wrap f_jc_b">
					<div class="card">
						<p class="dotted-title">粉丝增长趋势</p>
						<div class="flex_r f_ai_c progress">
							<img
								width="25"
								height="35"
								src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/woman.png"
								alt=""
							/>
							<div class="flex1 ml10 mr10">
								<el-progress
									:percentage="40"
									:show-text="false"
								></el-progress>
								<div class="flex_r f_jc_b mt10">
									<span>女性：85.11%</span>
									<span>男性：14.89%</span>
								</div>
							</div>
							<img
								width="25"
								height="35"
								src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/man.png"
								alt=""
							/>
						</div>
					</div>
					<div class="card">
						<p class="dotted-title">粉丝年龄分布</p>
						<e-charts
							id="idPiezz12"
							:options="optionPie"
							class="h100"
						/>
					</div>
					<div class="card">
						<p class="dotted-title">粉丝地域分布</p>
						<e-charts
							id="idPie12"
							:options="optionPie"
							class="h100"
						/>
					</div>
					<div class="card">
						<p class="dotted-title">粉丝消费品类分布</p>
						<e-charts
							id="idPie33"
							:options="optionPie3"
							class="h100"
						/>
					</div>
				</div>
			</div>
			<div class="data-area dataarea" v-if="cur === 2">
				<div class="flex_r f_jc_b">
					<div>
						<span class="mr10">场次日期</span>
						<el-date-picker
							v-model="value1"
							type="daterange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
						>
						</el-date-picker>
					</div>
					<div>
						<el-radio-group
							v-model="tabPosition"
							size="large"
							style="margin-bottom: 30px;"
						>
							<el-radio-button label="1">昨天</el-radio-button>
							<el-radio-button label="2">近3天</el-radio-button>
							<el-radio-button label="2">近7天</el-radio-button>
							<el-radio-button label="2">近15天</el-radio-button>
							<el-radio-button label="2">近30天</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div class="pies flex_r flex_wrap f_jc_b">
					<div class="card">
						<p class="dotted-title">场均在线人数</p>
						<e-charts
							id="idLinezxxxz"
							:options="optionLine"
							class="h100"
						/>
					</div>
					<div class="card">
						<p class="dotted-title">场均销量</p>
						<e-charts
							id="idLinezxz"
							:options="optionLine"
							class="h100"
						/>
					</div>
				</div>
				<div>
					<p class="dotted-title">场均在线人数</p>
					<div class="liveitem">
						
						<router-link  class="fr" tag="a" target="_blank" :to="{path:'/activeDetails'}">
							<el-button>查看详情</el-button>
						</router-link>
						
						<div class="fs16" style="font-weight: 600;">
							2021-06-10日直播数据
						</div>
						<div class="fs14 flex" style="margin: 15px 0px 40px;">
							<p>
								<span class="color-2">本场主题：</span>
								罗拉密码-年中大促服装专场
							</p>
							<p style="margin-left: 40px;">
								<span class="color-2">开播时间：</span>
								2021-06-10 00:00:11
							</p>
							<p style="margin-left: 40px;">
								<span class="color-2">直播时长：</span>
								1小时45分钟
							</p>
						</div>
						<div
							class="flex_r flex-pack-justify f_jc_c f_ai_c row_item"
							style="padding: 0px 25px;"
						>
							<div>
								<p class="fs14 color-2">
									本场销量
								</p>
								<p class="fs24 sessionval">
									37.45万件
								</p>
							</div>
							<div class="cut-line"></div>
							<div>
								<p class="fs14 color-2">
									本场销售额
								</p>
								<p class="fs24 sessionval">
									3508.79万元
								</p>
							</div>
							<div class="cut-line"></div>
							<div>
								<p class="fs14 color-2">
									本场平均价格
								</p>
								<p class="fs24 sessionval">
									¥93.69元
								</p>
							</div>
							<div class="cut-line"></div>
							<div>
								<p class="fs14 color-2">
									本场累计观看人数
								</p>
								<p class="fs24 sessionval">
									324.10万
								</p>
							</div>
							<div class="cut-line"></div>
							<div>
								<p class="fs14 color-2">
									本场商品数
								</p>
								<p class="fs24 sessionval">
									157
								</p>
							</div>
						</div>
					</div>
					<el-pagination
						class="tr"
						:small="true"
						:current-page="1"
						:page-size="10"
						:total="0"
					>
					</el-pagination>
				</div>
			</div>
			<div class="data-area dataarea" v-if="cur === 3">
				<div class="flex_r f_jc_b">
					<p class="dotted-title">带货速览</p>
					<div>
						<el-radio-group
							v-model="tabPosition"
							size="large"
							style="margin-bottom: 30px;"
						>
							<el-radio-button label="1">昨天</el-radio-button>
							<el-radio-button label="2">近3天</el-radio-button>
							<el-radio-button label="2">近7天</el-radio-button>
							<el-radio-button label="2">近15天</el-radio-button>
							<el-radio-button label="2">近30天</el-radio-button>
						</el-radio-group>
					</div>
				</div>
				<div class="datainfo mb30">
					<div class="dataitem">
						<p class="fs14 color-2">累计带货商品数</p>
						<p class="fs24">241件</p>
					</div>
					<div class="cut-line"></div>
					<div style="" class="dataitem">
						<p class="fs14 color-2">累计带货商品销量</p>
						<p class="fs24">241件</p>
					</div>
					<div class="cut-line"></div>
					<div class="dataitem">
						<p class="fs14 color-2">
							场均带货商品数量
						</p>
						<p class="fs24">12.48件</p>
					</div>
					<div class="cut-line"></div>
					<div class="dataitem">
						<p class="fs14 color-2">
							场均带货直播价
						</p>
						<p class="fs24">1063.45件</p>
					</div>
					<div class="cut-line"></div>
					<div class="dataitem">
						<p class="fs14 color-2">
							近30天场均观看人数
						</p>
						<p class="fs24">159.35万</p>
					</div>
				</div>
				<div>
					<div class="flex_r f_jc_b">
						<p class="dotted-title">带货记录</p>
						<el-input class="w25" size="large">
							<el-button slot="append">搜索</el-button>
						</el-input>
					</div>
					<div class="data_type flex_r f_ai_c bg_fff mb20 mt20">
						<span class="cr_999 mr10">主要品类：</span>
						<ul class="flex_r f_ai_c">
							<li class="mr10 cur">全部</li>
							<li class="mr10">服装鞋包</li>
							<li class="mr10">美妆饰品</li>
						</ul>
					</div>
					<e-table
						:tableCols="foundationTableConf"
						:dataOrigin="dataOrigin"
					>
						<template #1>
							<div class="flex_r">
								<img
									class="pr10"
									height="40"
									width="40"
									src="//gw.alicdn.com/bao/uploaded/i1/2980888022/O1CN01kMxlkA2987vRQ02IF_!!2980888022-0-picasso.jpg"
									alt=""
								/>
								<div>
									<div class="tl">薇娅viya</div>
									<span>ID： 69226163</span>
								</div>
							</div>
						</template>
						<template #2>
							<div>
								<img
									class="pr10"
									height="16"
									width="16"
									src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/tb.png?v=1623338071190"
									alt=""
								/>
								<span>淘宝</span>
							</div>
						</template>
					</e-table>
				</div>
			</div>
			<div
				v-if="cur === 4"
				class="service-contact dataarea"
			>
				<div class="box">
					<div class="dotted-title">信息介绍</div>
					<div class="content">
						<div class="row">
							<div class="row-l">
								服务类型：
							</div>
							<div class="row-r">直播</div>
						</div>
						<div class="row">
							<div class="row-l">
								自我介绍：
							</div>
							<div class="row-r">
								<p class="blockquote">
									<span class="bold"
										>点开查看详细资料有对应的合作联系方式</span
									>
								</p>
								<!---->
								<ul>
									<li>
										<p class="">
											<span
												>薇娅viya，淘宝第一主播，全球好物推荐官。淘宝粉丝数2820万+，单场直播最高观看数1.17亿+</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>单场直播最高点赞数7.7亿+，单链接引导成交额最高1.6亿+，单天直播引导成交额最高10亿+</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>2019年双十一期间引导成交额30亿+。薇娅零食节引导成交额最高2.35亿+</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>生活节引导成交额最高3亿+。全球好物甄选韩国站总引导成交1.1亿+</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>泰国站总引导成交1.14亿+，新西兰站总引导成交1.34亿+</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>连续4年在330盛典上获得淘宝直播官方嘉奖。</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>被宝洁、飞利浦、上海家化、复兴、立白等多家全球知名品牌授予“全球好物推荐官”称号。</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
											
												class="bold"
											>
												招商请联系：</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>商务合作咨询：元芳 ：
												（钉钉号：z1084421900）</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>食品类：静静（食品类负责人
												钉钉号：jingjingmayday)</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>生活类：煜彤（生活类负责人
												钉钉号：guojieyun）</span
											>
										</p>
									</li>
								</ul>
								<ul>
									<li>
										<p class="">
											<span
												>美妆类：艳艳（美妆类负责人
												钉钉号：zyanyan134）
											</span>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="dotted-title">服务详情</div>
					<div class="content anchor">
						<div class="anchor-item active">
							<img
							
								src="https://img.alicdn.com/imgextra/i2/69226163/TB2QuuduH1YBuNjSszhXXcUsFXa_!!69226163-0-daren.jpg"
								alt=""
								class="anchor-item-img"
							/>
							<div class="anchor-item-r">
								<div
								
									class="anchor-item-r-title"
								>
									薇娅viya12月V任务报价
								</div>
								<div
								
									class="anchor-item-r-price"
								>
									¥40000.00-220000.00
								</div>
								<div
								
									class="anchor-item-r-group"
								>
									<span class="title"
										>套餐数：</span
									><span class="num"
										>9</span
									>
								</div>
								<div
								
									class="anchor-item-r-group"
								>
									<span class="title"
										>合作次数：</span
									><span class="num"
										>9071次</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box">
					<div class="content">
						<a
							
							target="_blank"
							href="https://v.taobao.com/v/price/detail?spm=a21xh.11250901.priceList.8.5fcc6b6f2YglsJ&amp;price_id=66188&amp;c=701"
							class="hot f-flex-center f-flex-vcenter fr"
							><img
							
								src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/hot.png"
								alt=""
								class="hot-icon"
							/>
							<el-button>去报名</el-button>
							</a
						>
						<div class="row">
							<div class="row-l">
								服务价格：
							</div>
							<div class="row-r">¥600.00</div>
						</div>
						<div class="row">
							<div class="row-l">
								内容类型：
							</div>
							<div class="row-r">
								直播-直播套餐
							</div>
						</div>
						<div class="row">
							<div class="row-l">
								服务承诺：
							</div>
							<div class="row-r">
								24小时响应
							</div>
						</div>
						<div class="row">
							<div class="row-l line1">
								方案：
							</div>
							<div class="row-r">
								<div class="tags-box">
									<div class="tags">
										<el-button type="danger">生活（新）</el-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { optionLine, optionPie } from '@data/dashboard';
import { foundationTableConf } from '@data/anchorData';
export default {
	name: 'Foundation',
	data() {
		return {
			optionLine,
			foundationTableConf,
			optionPie,
			cur: 1,
			dataOrigin: {
				data: [{}],
			},
		};
	},
	methods: {
		change(val) {
			this.cur = val;
		},
	},
};
</script>

<style lang="scss">
.infoarea {
	width: 100%;
	padding: 10px 25px 25px 25px;
	box-sizing: border-box;
	background: #fcfcfc;
	position: relative;
	border: 1px solid #ebecee;
	border-radius: 4px;
	margin-bottom: 10px;
}
.flex {
	display: flex;
}
.infoarea img {
	width: 120px;
	height: 120px;
	border-radius: 10px;
	margin-right: 20px;
}
.infoarea .info {
	max-width: 753px;
}
.infoarea .info p:first-of-type {
	margin: 10px 0 20px 0;
	font-weight: 600;
}
.infoarea .info p:nth-of-type(2) {
	margin-bottom: 13px;
}
.fs14 {
	font-size: 16px;
}
.infoarea .title {
	padding-left: 5px;
	border-left: 2px solid #000;
	height: 10px;
	margin: 20px 0 35px 0;
	line-height: 1;
}
.infoarea .datainfo {
	display: flex;
	align-items: center;
}
.infoarea .datainfo .dataitem {
	text-align: center;
	font-weight: 600;
}
.infoarea .datainfo > div {
	margin: 0 36px;
}
.infoarea .datainfo .dataitem p:last-of-type {
	margin-top: 15px;
	font-weight: 400;
}
.infoarea .datainfo .cut-line {
	width: 1px;
	height: 40px;
	background: #e5e5e5;
}
.infoarea .datainfo > div {
	margin: 0 36px;
}
.screen-tab {
	width: 152px;
	border: 1px solid #ebecee;
	border-radius: 4px;
	margin-right: 10px;
	padding: 13px 0;
}
.screen-tab .item-active {
	color: #fc2628;
}
.screen-tab .screen-div-item {
	padding: 15px 0 15px 34px;
	font-size: 14px;
	color: #252525;
	cursor: pointer;
	display: flex;
	align-items: center;
}
.screen-tab .screen-div-item img {
	width: 14px;
	height: 14px;
	margin-right: 10px;
}
.screen-tab .item-active {
	color: #fc2628;
}
.data-area {
	border: 1px solid #ebecee;
	border-radius: 4px;
	padding: 20px;
	flex: 1;
}
.goods-item {
	margin-bottom: 30px;
}
.goods-item .dotted-title {
	color: #030303;
	font-size: 14px;
	padding-left: 12px;
	position: relative;
}
.class-item {
	margin-top: 20px;
}
.class-item span {
	padding: 5px 8px;
	background: #ffab63;
	color: #fff;
	border-radius: 4px;
	margin-right: 12px;
	font-size: 12px;
}
.goods-item {
	margin-bottom: 30px;
}
.datainfo {
	margin-top: 20px;
	padding: 22px 0;
	background: #fffaf5;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	border-radius: 4px;
}
.datainfo .dataitem {
	text-align: center;
}
.color-2 {
	color: #8b8b8d;
}
.datainfo .dataitem p:last-of-type {
	margin-top: 15px;
	font-weight: 600;
}
.datainfo .cut-line {
	width: 1px;
	height: 40px;
	background: #e5e5e5;
}
.cargo-num {
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
}
.cargo-num .cargo-num-item {
	width: 314px;
	display: flex;
	background: #f8f8f8;
	height: 56px;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
}
.cargo-num .cargo-num-item span {
	color: #8b8b8d;
	font-size: 14px;
	margin-right: 10px;
}
.cargo-num .cargo-num-item p {
	flex: 1;
	text-align: right;
	color: #000;
	font-size: 24px;
	font-weight: 600;
}
.line {
	height: 400px;
}
.card {
	width: 49%;
	box-sizing: border-box;
	height: 400px;
}
.progress {
	margin-top: 200px;
}
.liveitem {
	background: rgb(246, 246, 246);
	border-radius: 10px;
	margin-top: 20px;
	padding: 20px;
	position: relative;
}
.flex-pack-justify {
	justify-content: space-between;
}
.color-2 {
	color: #8b8b8d;
}
.liveitem .sessionval {
	margin-top: 20px;
	font-weight: 600;
}
.liveitem .cut-line {
	width: 1px;
	height: 40px;
	background: rgb(229, 229, 229);
}
.row_item > div {
	text-align: center;
}
.dataarea{
    flex: 1;
}
.service-contact .box {
    margin-bottom: 10px;
    padding: 0 20px 20px;
    border-radius: 4px;
    border: 1px solid #ebecee;
}
.service-contact .dotted-title {
    color: #030303;
    font-size: 14px;
    padding-left: 12px;
    position: relative;
    margin-top: 15px;
}
.service-contact .content {
    margin-top: 12px;
    padding: 8px 10px;
    position: relative;
}
.service-contact .row {
    display: flex;
    margin-bottom: 5px;
    font-size: 14px;
}
.service-contact .row-l {
    color: #808b8d;
    line-height: 1.4;
    flex: none;
}
.service-contact .row-r {
    color: #030303;
    line-height: 1.4;
}
.blockquote {
    border-left: 4px solid #e9e9e9;
    padding-left: 5px;
}
.service-contact .content {
    margin-top: 12px;
    padding: 8px 10px;
    position: relative;
}
.anchor{
    padding: 10px;
    display: flex;
    overflow-x: auto;
}
.anchor-item {
    min-width: 280px;
    height: 130px;
    border: 1px solid #ebecee;
    padding: 10px 15px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
}
.anchor-item.active {
    border-color: #ff9f9f;
}
.anchor-item-img {
    flex: none;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    margin-right: 5px;
}
.anchor-item-r-price {
    font-size: 14px;
    font-weight: 600;
    color: #f85d5e;
    line-height: 22px;
}
.anchor-item-r-group {
    font-size: 14px;
    color: #101011;
}
.anchor-item-r-title {
    font-weight: 600;
    font-size: 16px;
    color: #101011;
    line-height: 22px;
}
.anchor-item-r {
    height: 100px;
}
.anchor-item-r>div {
    margin: 4px 0;
}
.anchor-item-r>div {
    margin: 4px 0;
}
.anchor-item-r-group>.title {
    color: #808b8d;
}
</style>
