export const optionBar = {
    legend: {},
    tooltip: {},
    dataset: {
        source: [
            ['product', ''],
        ]
    },
    xAxis: {type: 'category'},
    yAxis: {},
	grid: {
		left: '3%',
		right: '3%',
		bottom: '3%',
		containLabel: true
	},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
        {type: 'bar'},
        {type: 'bar'},
        {type: 'bar'}
    ]
};

export const optionPie = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        top: '5%',
        left: 'center'
    },
    series: [
        {
            name: '销售额',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '40',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: []
        }
    ]
};

export const recordTableConf = [
	{
		label: '登录账号',
		prop: 'mobile'
	},
	{
		label: '登录次数',
		prop: 'loginCount'
	},
	{
		label: '通过寄样商品数',
		prop: 'jyCount'
	},
	{
		label: '入库商品数',
		prop: 'rkCount'
	},
	{
		label: '排期商品数',
		prop: 'pqCount'
	},
	{
		label: '不通过商品数',
		prop: 'bhCount'
	},
	{
		label: '最后登录时间',
		prop: 'prevtime'
	}
]

export const optionLine = {
	grid: {
		left: '3%',
		right: '3%',
		bottom: '3%',
		top: '10%',
		containLabel: true
	},
    xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        data: [0, 2, 9, 34, 129, 30, 20],
        type: 'line',
        smooth: true
    }]
};